import React from 'react';
import { Layout } from '../components/Layout';
import imageBestInCalgary from '../images/bestInCalgary.png';

const Links = () => (
  <Layout
    title="Check out some useful links from Axiom Geomatics "
    description="Surviving is a very interconnected discipline, here is some link to others we work with and people you might want to connect with."
  >
    <main>
      <h1>Links you may find useful</h1>

      <br />

      <p>
        <a
          className="noHover"
          href="https://www.thebestcalgary.com/best-land-surveyors-calgary/"
          rel="nofollow noopener"
        >
          <img src={imageBestInCalgary} alt="Best in Calgary badge" />
        </a>
      </p>
    </main>
  </Layout>
);

export default Links;
